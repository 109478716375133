<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc4_0.jpg'),
        //   title: '工程财税',
        //   intro: '精准化税点计算'
        // },
      ],
      info: {
        title: '工程财税',
        desc: '在工程项目中对财务和税务进行管理和控制的一系列活动。这些包括资金管理、成本管理、合同管理和税务管理等，以确保项目顺利实施并保持企业财务状况稳定。工程财税流程涉及多个环节，如纳税人登记、税务申报、制定财税计划、资金筹措和预算分配、合同和税务管理、成本控制和监督、审计和结算以及纳税申报和税务审查。',
        list: [
          { id: 1, title: '工程启动' },
          { id: 2, title: '预算编制' },
          { id: 3, title: '成本控制' },
          { id: 4, title: '财务报告' },
          { id: 5, title: '税务申报' },
          { id: 6, title: '工程结束' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc4_1.jpg'),
          tit: '税务代理',
          desc: '为各类企业提供税务咨询、税务筹划等服务，帮助企业合理规避税务风险。'
        }, {
          id: 2,
          img: require('@/assets/images/svc4_2.jpg'),
          tit: '日常财税咨询',
          desc: '为企业提供日常财税咨询服务，包括税务政策解答、税务手续指导等。'
        }, {
          id: 3,
          img: require('@/assets/images/svc4_3.jpg'),
          tit: '财税审计',
          desc: '为企业提供财税审计服务，包括财务报表审计、税务审计等，帮助企业提高财务管理水平。'
        }, {
          id: 4,
          img: require('@/assets/images/svc4_4.jpg'),
          tit: '定制服务',
          desc: '根据企业的实际需求，为企业定制个性化的财税服务方案，满足企业的特定需求。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>